import { FieldPath, FieldValues, UseFormReturn, get } from 'react-hook-form'

export function assertUnreachable(arg: never): never {
  throw new Error(`Didn't expect arg [${arg}] to get here`)
}

export function isDate(val: unknown) {
  return val instanceof Date && !isNaN(val as unknown as number)
}

type GetFormErrorArgs<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName
  form: UseFormReturn<TFieldValues>
}

export function getFormError<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(args: GetFormErrorArgs<TFieldValues, TName>) {
  try {
    return get(args.form.formState.errors, `${args.name}.message`)
  } catch {
    return null
  }
}

'use client'

import {
  faCircleExclamation,
  faEye,
  faEyeSlash
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { getFormError } from 'utils/types'

type InputProps = Omit<TextFieldProps, 'name'> & { name: string }

export function Input({ name, value, type, ...restProps }: InputProps) {
  const form = useFormContext()

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const error = getFormError({
    form,
    name
  })

  return (
    <TextField
      name={name}
      type={type === 'password' && showPassword ? 'text' : type}
      error={!!error}
      value={value || ''}
      helperText={
        error && (
          <>
            <FontAwesomeIcon icon={faCircleExclamation} /> {error}
          </>
        )
      }
      InputProps={{
        endAdornment: type === 'password' && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                size="xs"
              />
            </IconButton>
          </InputAdornment>
        )
      }}
      {...restProps}
    />
  )
}
